.LinkGrid__container {
    width: 100%;
    margin-top: 15px;
    display: flex;  
    flex-wrap: wrap;
}

.LinkGrid__header {
    width: 100%;
    font-weight: bold;
    font-size: large;
    display: block;
}

.LinkGrid__link {
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: large;
}

.LinkGrid__data {
    display: flex;  
    flex-wrap: wrap;

    margin-top: 3px;
    border-style: solid;
    border-color: gray;
    width: 100%;

}