.Home__estados_container {
    width: 100%;
    margin-top: 15px;
    display: flex;  
    flex-wrap: wrap;
    justify-content: space-between;
}

.Home__estados_header {
    width: 100%;
    font-weight: bold;
    font-size: large;
    display: block;
}

.Home__estados_link {
    font-size: large;
    margin-right: 1.5em;
    text-decoration: underline;
}

.Home__estados_data {
    display: flex;  
    flex-wrap: wrap;
    padding: 10px;

    margin-top: 3px;
    border-style: solid;
    border-color: gray;
    width: 100%;

}