.ZipView__searchRow {
    margin-bottom: 30px;
}

.ZipView__container {
    /* padding: 10px; */
}

.ZipView__breadcrumb {

}

.ZipView__zipview {
  /*  margin-top: 40px;*/
}