.SearchTownForm__typeahead {
    width: 100%;
}


  
  .toggle-button {
    background: transparent;
    border: 0;
    bottom: 0;
    margin-right: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }