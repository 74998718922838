.ZipCard__container {
  font-size: 1.1em;
  margin-bottom: 10px;
  width: 100%;
  min-height: 150px;
  max-width: 800px;
  background: #e9ecef;
  -webkit-filter: drop-shadow(3px 3px 1px black);
  filter: drop-shadow(0px 0px 5px white); 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
	border-image: 16 repeating-linear-gradient(-30deg, #cc0000 0, #cc0000 1em, transparent 0, transparent 2em,
  #006600 0, #006600 3em, transparent 0, transparent 4em);
}

.ZipCard__zipcode {
  text-align: right;
  margin-right: 10px;
}

.ZipCard_zipcode_txtarea {
  padding: 10px;
}

.ZipCard__asentamientos {
    font-family: 'Courier New', Courier, monospace;
    font-size: medium;
}

.ZipCard__zipcodetxt {
  font-family: 'Courier New', Courier, monospace;
  font-size: large;
  padding: 2px 10px 2px 10px;
  border-style: solid;
  border-color: red;
}

.ZipCard__data_item {
  font-family: 'Courier New', Courier, monospace;
  margin-left: 5px;
}

.ZipCard__header {
  font-weight: bold;
}
